import gql from 'graphql-tag';

export default gql`
	mutation deactivateSurvey($uid: String!) {
		deactivateSurvey(input: { uid: $uid }) {
			uid: id
			active
		}
	}
`;
