import gql from 'graphql-tag';

export default gql`
	query Survey($uid: String!) {
		survey: surveyNew(uid: $uid) {
			uid: id
			title
			allowRepeat
			customThemes
			theme
			questions {
				id
			}
			content {
				introPage {
					title
					content
					startButton
					logo {
						url
					}
				}
				mainPages {
					id
					hash
					items {
						... on QuestionInterface {
							id
							text
							helpText
							required
							ordinal
							number
							edited
							answered
							logic
							picture {
								url
								w
								h
							}
							video {
								url
								thumb
							}
						}
						... on ChoiceQuestion {
							hasPictureChoices
							layout
							isMultipleAnswer
							minimumAnswers
							maximumAnswers
							layout
							isQuizItem
							isEditedLimit
							choices {
								id
								text
								open
								ordinal
								quizPoints
								picture {
									url
								}
							}
						}
						... on MatrixQuestion {
							type
							minimumAnswers
							maximumAnswers
							isEditedLimit
							cols {
								id
								text
								ordinal
							}
							rows {
								id
								text
								ordinal
							}
						}
						... on ImportanceQuestion {
							rows {
								text
								id
								ordinal
							}
						}
						... on RatingQuestion {
							pointCount
						}
						... on NetPromoterQuestion {
							leftText
							rightText
						}
						... on SemanticDifferentialQuestion {
							pointCount
							rows {
								id
								leftLabel
								rightLabel
								ordinal
							}
						}
						... on TextQuestion {
							subtype: type
							answerLengthLimit
						}
						... on DividePointsQuestion {
							rows {
								text
								id
								ordinal
							}
							pointCount
							units
						}
						... on TextBlock {
							id
							ordinal
							text
						}
					}
				}
				thanksPage {
					title
					content
				}
				submitButtonText
				repeatButtonText
			}
			settings {
				numberQuestions
			}
		}
	}
`;
