import gql from 'graphql-tag';

export default gql`
	query choicedPivotData($questionId_1: ID!, $questionId_2: ID!, $survey: String!) {
		survey: surveyNew(uid: $survey) {
			uid: id
			choicedPivotData(questionId_1: $questionId_1, questionId_2: $questionId_2) {
				breakdown {
					count
					answer {
						id
						text
					}
					breakdown {
						count
						answer {
							id
							text
						}
					}
				}
			}
		}
	}
`;
