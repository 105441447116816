import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { ButtonProps } from './';

// TODO: ButtonBase should be imported from './' as well (@babel/plugin-proposal-export-default-from)
import ButtonBase from './ButtonBase';
import { getThemeColor } from '../../utils';

const buttonPadding = ({ size, startIcon }: ButtonProps) => css`
	padding: ${(() => {
		switch (size) {
			case 'xl':
				return startIcon ? '15px 15px' : '15px 23px';
			case 'lg':
				return startIcon ? '11px 10px' : '11px 19px';
			case 'sm':
				return startIcon ? '6px 7px 6px 11px' : '7px 12px';
			case 'xs':
				return startIcon ? '3px 3px' : '3px 9px';
			case '36':
				return '7px 11px';
			case '40':
				return '9px 15px';
			default:
			case 'md':
				return startIcon ? '9px 12px' : '9px 16px';
		}
	})()};
`;

const borderedIconPadding = ({ size }: ButtonProps) => css`
	padding: ${(() => {
		switch (size) {
			case 'xl':
				return '15px';
			case 'lg':
				return '11px';
			case 'sm':
				return '7px';
			case 'xs':
				return '3px';
			default:
			case 'md':
				return '11px';
		}
	})()};
`;

const iconPadding = ({ size }: ButtonProps) => css`
	padding: ${(() => {
		switch (size) {
			case 'xl':
				return '17px';
			case 'lg':
				return '13px';
			case 'sm':
				return '8px';
			case 'xs':
				return '6px';
			default:
			case 'md':
				return '13px';
		}
	})()};
`;

const iconButtonStyle = ({ disabled, rounded, size }: ButtonProps) => css`
	line-height: 1;
	border-radius: ${rounded ? '20px' : ['lg', 'xl'].includes(size) ? '16px' : '10px'};

	.startIcon {
		margin-right: 0;
		font-weight: normal;
		z-index: 1;
		opacity: ${disabled ? 0.16 : 1};
	}
`;

const GeneralButton = styled(ButtonBase)<Omit<ButtonProps, 'onClick'>>`
	${({ size, theme }) => {
		switch (size) {
			case 'xl':
				return theme.typography.textSemibold400;
			case 'lg':
				return theme.typography.textSemibold400;
			case 'sm':
				return theme.typography.textSemibold200;
			case 'xs':
				return theme.typography.textSemibold200;
			case '40':
				return theme.typography.textMedium300;
			default:
			case 'md':
				return theme.typography.textMedium300;
		}
	}};
	display: inline-flex;
	justify-content: center;
	width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
	border: 1px solid transparent;
	cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
	// Next two lines are for prevent flickering
	-webkit-transform-style: preserve-3d;
	-webkit-backface-visibility: hidden;

	.buttonContent {
		${buttonPadding};
		opacity: ${({ disabled }) => (disabled ? 0.16 : 1)};
		margin-right: ${({ size, startIcon }) => {
			if (startIcon) {
				switch (size) {
					case 'xl':
						return '8px';
					case 'lg':
						return '8px';
					case 'sm':
						return '8px';
					case 'xs':
						return '4px';
					default:
					case 'md':
						return '2px';
				}
			} else {
				return 0;
			}
		}};
	}

	.startIcon {
		margin-right: 8px;
		font-weight: normal;
		color: inherit;
	}
`;

export const ContainedButton = styled(GeneralButton)<Omit<ButtonProps, 'onClick'>>`
	color: ${({ color, disabled, theme }) =>
		color === 'yellow_black' || disabled
			? theme.colors.black[100]
			: color === 'ghost'
				? theme.colors.grey_shades_with_blue[600]
				: color === 'secondary'
					? theme.colors.secondary[700]
					: color === 'primary_200' || color === 'primary_250' || color === 'primary_225'
						? theme.colors.primary[900]
						: color === 'red_200'
							? theme.colors.upgrade[800]
							: theme.colors.white};
	border-radius: ${({ rounded, size }) =>
		rounded ? '20px' : ['lg', 'xl'].includes(size) ? '12px' : '8px'};
	border: ${({ color, disabled, theme }) =>
		color === 'ghost' && !disabled
			? `1px solid ${theme.colors.grey_shades_with_blue[200]}`
			: `1px solid transparent`};
	box-sizing: border-box;
	background-color: ${({ disabled, theme, color }) => {
		if (!disabled) {
			switch (color) {
				case 'red':
					return theme.colors.red[600];
				case 'ghost':
					return theme.colors.white;
				case 'yellow_black':
					return theme.colors.yellow[400];
				case 'yellow':
					return theme.colors.yellow[500];
				case 'active_blue':
					return theme.colors.active_blue[500];
				case 'purple':
					return theme.colors.avatar_colors.purple[500];
				case 'old_green':
					return theme.colors.old.green[200];
				case 'upgrade':
					return theme.colors.upgrade[500];
				case 'blue':
					return theme.colors.active_blue[500];
				case 'secondary':
					return theme.colors.secondary[200];
				case 'primary_200':
					return theme.colors.primary[200];
				case 'primary_225':
					return theme.colors.primary[225];
				case 'primary_250':
					return theme.colors.primary[250];
				case 'red_200':
					return theme.colors.red[200];
				case 'primary':
					return theme.colors.primary[800];
				default:
					const [baseColor, index] = color.split(/[\[\]]/).filter(Boolean);
					return theme.colors[baseColor][index];
			}
		} else {
			return theme.colors.grey[300];
		}
	}};

	${({ theme, color }) => (color === 'ghost' ? theme.shadows[5] : '')}
	${({ theme, color }) => (color === 'ghost' ? theme.typography.textMedium300 : '')}
	&:hover,
	&.isActive {
		background-color: ${({ disabled, color, theme }) => {
			if (!disabled) {
				switch (color) {
					case 'red':
						return theme.colors.red[500];
					case 'ghost':
						return theme.colors.grey_shades_with_blue[50];
					case 'yellow_black':
						return theme.colors.yellow[300];
					case 'yellow':
						return theme.colors.avatar_colors.yellow[600];
					case 'active_blue':
						return theme.colors.active_blue[600];
					case 'purple':
						return theme.colors.avatar_colors.purple[600];
					case 'old_green':
						return theme.colors.old.green[300];
					case 'upgrade':
						return theme.colors.upgrade[600];
					case 'blue':
						return theme.colors.active_blue[600];
					case 'secondary':
						return theme.colors.secondary[300];
					case 'primary_200':
						return theme.colors.primary[150];
					case 'primary_225':
						return theme.colors.primary[175];
					case 'primary_250':
						return theme.colors.primary[200];
					case 'red_200':
						return theme.colors.red[150];
					case 'primary':
						return theme.colors.primary[700];
					default:
						const [baseColor, index] = color.split(/[\[\]]/).filter(Boolean);
						return theme.colors[baseColor][Number(index) - 100];
				}
			} else {
				return theme.colors.grey[300];
			}
		}};
		border: ${({ disabled, color, theme }) => {
			let borderColor = 'transparent';
			if (color === 'ghost') {
				borderColor = disabled
					? theme.colors.grey_shades_with_blue[50]
					: theme.colors.grey_shades_with_blue[200];
			}
			return `1px solid ${borderColor};`;
		}};
	}

	&:active {
		background-color: ${({ disabled, color, theme }) => {
			if (!disabled) {
				switch (color) {
					case 'red':
						return theme.colors.red[700];
					case 'ghost':
						return theme.colors.grey_shades_with_blue[100];
					case 'yellow_black':
						return theme.colors.yellow[500];
					case 'yellow':
						return theme.colors.avatar_colors.yellow[700];
					case 'active_blue':
						return theme.colors.active_blue[700];
					case 'purple':
						return theme.colors.avatar_colors.purple[700];
					case 'old_green':
						return theme.colors.old.green[100];
					case 'upgrade':
						return theme.colors.upgrade[700];
					case 'blue':
						return theme.colors.active_blue[700];
					case 'secondary':
						return theme.colors.secondary[400];
					case 'primary_200':
						return theme.colors.primary[200];
					case 'primary_225':
						return theme.colors.primary[175];
					case 'primary_250':
						return theme.colors.primary[250];
					case 'red_200':
						return theme.colors.red[200];
					case 'primary':
						return theme.colors.primary[800];
					default:
						const [baseColor, index] = color.split(/[\[\]]/).filter(Boolean);
						return theme.colors[baseColor][index];
				}
			} else {
				return theme.colors.grey[300];
			}
		}};
		border: ${({ disabled, color, theme }) => {
			let borderColor = 'transparent';
			if (color === 'ghost') {
				borderColor = disabled
					? theme.colors.grey_shades_with_blue[50]
					: theme.colors.grey_shades_with_blue[200];
			}
			return `1px solid ${borderColor};`;
		}};
	}
`;

export const SimpleLink = styled(GeneralButton)<Omit<ButtonProps, 'onClick'>>`
	${({ theme: { typography }, size, typo }) =>
		typo
			? typography[typo]
			: size === 'sm'
				? typography.textSemibold200
				: typography.textSemibold300};
	color: ${({ theme, theme: { colors }, variant, disabled, simpleColor }) =>
		disabled
			? colors.grey_shades_with_blue[300]
			: simpleColor
				? getThemeColor(simpleColor, theme)
				: variant === 'simpleLinkGrey'
					? colors.grey_shades_with_blue[500]
					: variant === 'simpleLinkBlue'
						? colors.primary[500]
						: colors.black[100]};
	transition: color 200ms ease-out;

	&:hover {
		color: ${({ theme, disabled, hover }) =>
			disabled
				? theme.colors.grey_shades_with_blue[300]
				: hover
					? getThemeColor(hover, theme)
					: theme.colors.black[100]};
	}

	&:active {
		color: ${({ theme: { colors }, disabled, hover }) =>
			disabled ? colors.grey_shades_with_blue[300] : hover ? hover : colors.black[100]};
	}
`;

export const SimpleLink250 = styled(GeneralButton)<Omit<ButtonProps, 'onClick'>>`
	${({ theme }) => theme.typography.textSemibold250};
	color: ${({ theme, disabled }) =>
		disabled ? theme.colors.grey_shades_with_blue[300] : theme.colors.primary[500]};
	transition: color 200ms ease-out;

	.buttonContent {
		padding: 0;
	}

	&:hover {
		color: ${({ theme }) => theme.colors.black[100]};
	}

	&:active {
		color: ${({ theme }) => theme.colors.black[100]};
	}
`;

export const SimpleLink200 = styled(SimpleLink250)<Omit<ButtonProps, 'onClick'>>`
	${({ theme }) => theme.typography.textSemibold200};

	.buttonContent {
		padding: 0;
	}
`;

export const TextButton = styled(GeneralButton)<Omit<ButtonProps, 'onClick'>>`
	${({ theme }) => theme.typography.textSemibold300};
	color: ${({ theme, color, disabled }) => {
		if (disabled) return theme.colors.grey_shades_with_blue[300];
		switch (color) {
			case 'red':
				return theme.colors.red[400];
			case 'ghost':
				return theme.colors.grey_shades_with_blue[500];
			case 'yellow_black':
				return theme.colors.yellow[400];
			case 'yellow':
				return theme.colors.avatar_colors.yellow[500];
			case 'active_blue':
				return theme.colors.active_blue[500];
			case 'purple':
				return theme.colors.avatar_colors.purple[500];
			case 'grey':
				return theme.colors.grey_shades_with_blue[500];
			default:
			case 'blue':
				return theme.colors.primary[500];
		}
	}};
	border: none;
	overflow: visible;
	text-align: center;
	margin-right: 0;

	.buttonContent {
		padding: 0;
		opacity: 1;
	}

	.background {
		border-radius: 30px;
		position: absolute;
		width: 100%;
		height: 100%;
		// IE11 workaround (top, left + translate)
		transform: ${({ active }) => `translate(-50%, -50%) scale(${active ? 1 : 0.0001})`};
		transition: transform 200ms ease-out 0s;
		opacity: ${({ withDarkBackground, highlighted }) =>
			highlighted ? 1 : withDarkBackground ? 0.17 : 1};
		background-color: ${({ theme: { colors }, withDarkBackground, highlighted }) =>
			highlighted ? colors.primary[200] : withDarkBackground ? colors.white : '#3333631A'};
		// IE11
		top: 50%;
		left: 50%;
		${buttonPadding};
		box-sizing: content-box;
	}

	> span {
		width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
		display: flex;
		justify-content: center;
		//padding: 1px;
		margin-right: ${({ size, startIcon }) => {
			if (startIcon) {
				switch (size) {
					case 'xl':
					case 'lg':
					case 'sm':
						return '-8px';
					default:
					case 'xs':
					case 'md':
						return '-4px';
				}
			} else {
				return 0;
			}
		}};
	}

	&:hover {
		.background {
			transform: translate(-50%, -50%) scale(1);
		}
	}
`;

export const TextNoBgButton = styled(GeneralButton)<Omit<ButtonProps, 'onClick'>>`
	color: ${({ theme: { colors }, disabled }) =>
		disabled ? colors.grey_shades_with_blue[300] : colors.grey_shades_with_blue[500]};
	${({ size, theme: { typography } }) =>
		['xl', 'lg'].indexOf(size) !== -1
			? typography.textSemibold400
			: size === 'md'
				? typography.textSemibold300
				: typography.textSemibold200};
	transition: color 200ms ease-out;
	border: none;
	margin-right: 0;

	.buttonContent {
		padding: 0;
		opacity: 1;
	}

	&:hover {
		color: ${({ theme: { colors }, disabled }) =>
			disabled ? colors.grey_shades_with_blue[300] : colors.grey_shades_with_blue[700]};
	}

	> span {
		margin-right: ${({ size, startIcon }) => {
			if (startIcon) {
				switch (size) {
					case 'xl':
					case 'lg':
					case 'sm':
						return '-8px';
					default:
					case 'xs':
					case 'md':
						return '-4px';
				}
			} else {
				return 0;
			}
		}};
	}
`;

export const ImageButton = styled(ContainedButton)<Omit<ButtonProps, 'onClick'>>`
	${iconButtonStyle};

	.image {
		width: 24px;
		height: 24px;
		margin-right: 8px;
	}
`;

export const IconButton = styled(TextButton)<Omit<ButtonProps, 'onClick' | 'onMouseEnter'>>`
	${iconButtonStyle};
	color: ${({ theme: { colors }, withDarkBackground, highlighted }) =>
		highlighted
			? colors.primary[850]
			: withDarkBackground
				? colors.black[30]
				: colors.grey_shades_with_blue[400]};
	transition: transform 100ms ease-out 0s;
	border: none;
	// IE11

	&:active .startIcon {
		transition: transform 100ms ease-out 0s;
		transform: ${({ disabled }) => !disabled && 'translateY(1px)'};
	}

	.startIcon {
		transition: transform 100ms ease-out 0s;

		&:active {
			transform: ${({ disabled }) => !disabled && 'translateY(1px)'};
		}
	}

	.background {
		${iconPadding};
		border-radius: 6px;
	}

	> span {
		margin-right: 0;
	}
`;

export const BorderedIconButton = styled(ContainedButton)<Omit<ButtonProps, 'onClick'>>`
	${iconButtonStyle}
	.startIcon {
		${borderedIconPadding}
	}
`;

export const BlurredButton = styled(ContainedButton)<Omit<ButtonProps, 'onClick'>>`
	${iconButtonStyle}
	.startIcon {
		${iconPadding}
	}

	border: 1px solid rgba(0, 0, 0, 0.08);
	opacity: 1;

	&:hover {
		background: rgba(0, 0, 0, 0.3);
		backdrop-filter: blur(8px);
	}
`;

export const WithIconAndShadow = styled(BorderedIconButton)<Omit<ButtonProps, 'onClick'>>`
	border-color: ${({ theme }) => theme.colors.white};
	background-color: ${({ theme }) => theme.colors.white};
	color: ${({ theme }) => theme.colors.grey_shades_with_blue[500]};
	box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
	transition: all 200ms ease-in-out;

	&:hover {
		//transform: translateY(-2px);
		//box-shadow: 0 14px 24px rgba(0, 0, 0, 0.28);
		border-color: ${({ theme }) => theme.colors.white};
		background-color: ${({ theme }) => theme.colors.white};
		color: ${({ theme }) => theme.colors.grey_shades_with_blue[500]};
	}

	&:active {
		//transform: translateY(0);
		//box-shadow: 0 6px 24px rgba(0, 19, 41, 0.24);
		border-color: ${({ theme }) => theme.colors.white};
		background-color: ${({ theme }) => theme.colors.white};
		color: ${({ theme }) => theme.colors.grey_shades_with_blue[600]};
	}
`;

export const RoundedWithText = styled(GeneralButton)<Omit<ButtonProps, 'onClick'>>`
	${({ theme }) => theme.typography.title500};
	border: none;
	color: ${({ theme }) => theme.colors.primary[800]};
	transition:
		color 200ms ease-out,
		opacity 200ms ease-out;
	opacity: ${({ disabled }) => (disabled ? 0.2 : 1)};

	&:hover {
		color: ${({ theme, disabled }) => (!disabled ? theme.colors.primary[700] : undefined)};

		.startIcon {
			background-color: ${({ theme, disabled }) =>
				!disabled ? theme.colors.primary[700] : undefined};
		}
	}

	&:active {
		color: ${({ theme, disabled }) => (!disabled ? theme.colors.primary[800] : undefined)};

		.startIcon {
			background-color: ${({ theme, disabled }) =>
				!disabled ? theme.colors.primary[800] : undefined};
		}
	}

	.startIcon {
		color: ${({ theme }) => theme.colors.white} !important;
		background-color: ${({ theme }) => theme.colors.primary[800]};
		padding: ${({ theme }) => theme.helpers.spacer(2)}px;
		border-radius: 50%;
		transition: background-color 200ms ease-out;
		margin-right: ${({ theme }) => theme.helpers.spacer(2)}px;
	}
`;

export const HelpButton = styled(BorderedIconButton)<Omit<ButtonProps, 'onClick'>>`
	color: ${({ theme }) => theme.colors.white};
	background-color: ${({ theme }) => theme.colors.grey_shades_with_blue[800]};
	opacity: ${({ disabled }) => (!disabled ? 0.6 : 0.2)};
	transition: opacity 200ms ease-out;

	&:hover {
		background-color: ${({ theme }) => theme.colors.grey_shades_with_blue[800]};
		opacity: ${({ disabled }) => (!disabled ? 0.6 : 0.2)};
	}

	&:active {
		background-color: ${({ theme }) => theme.colors.grey_shades_with_blue[800]};
		opacity: ${({ disabled }) => (!disabled ? 0.4 : 0.2)};
	}
`;

export const ButtonLinkIcon = styled(IconButton)<Omit<ButtonProps, 'onClick'>>`
	color: ${({ theme }) => theme.colors.grey_shades_with_blue[350]};
	background-color: transparent;
	padding: 6px 12px;
	${({ theme }) => theme.typography.textMedium300};

	&:hover {
		background-color: ${({ theme }) => theme.colors.grey_shades_with_blue[25]};
	}

	&:active {
		background-color: ${({ theme }) => theme.colors.grey_shades_with_blue[25]};
	}
`;
