import gql from 'graphql-tag';

export default gql`
	query publicIdentifier($uid: String!) {
		survey: surveyNew(uid: $uid) {
			uid: id
			publicIdentifier
		}
	}
`;
