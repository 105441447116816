import gql from 'graphql-tag';

export default gql`
	query survey($uid: String!) {
		survey: surveyNew(uid: $uid) {
			uid: id
			integrations {
				googleDrive {
					integration {
						id
						email
						name
						avatar
					}
					activeSince
					folderName
					syncTime
				}
			}
		}
	}
`;
