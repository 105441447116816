import gql from 'graphql-tag';

export default gql`
	fragment AccountData on User {
		emailIsVerified
		phoneNumber
		fullName
		email
		language: languageCode
	}
`;
