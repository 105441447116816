import gql from 'graphql-tag';

const query = (children, fragments) => gql`
	query Survey($uid: String!) {
		survey: surveyNew(uid: $uid) {
			uid: id
			${children}
		}
	}
	${fragments}
`;

export default query;
