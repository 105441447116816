import gql from 'graphql-tag';

const query = (children, fragments) => gql`
		query survey(
			$uid: String!,
			$id: ID!,
			$perPage: PositiveNumber!,
			$page: PositiveNumber,
			$withStatusEither: [DeliveryStatus!]
		) {
			survey(uid: $uid) {
			uid: id
			 ${children}
			}
		}
		${fragments}
	`;

export default query;
