import gql from 'graphql-tag';

export default gql`
	query trafficHistory($uid: String!) {
		survey: surveyNew(uid: $uid) {
			trafficHistory(uid: $uid) {
				date
				visits
				submits
				unfinished
			}
		}
	}
`;
