import gql from 'graphql-tag';

export default gql`
	query responsesToBeRemoved($uid: String!, $first: Int) {
		survey: surveyNew(uid: $uid) {
			uid: id
			responseConnection(first: $first) {
				toBeRemovedCount
			}
		}
	}
`;
