import gql from 'graphql-tag';

import RESPONSE_FRAGMENT from '~/cache/fragments/results/response';

export default gql`
	query survey($uid: String!, $id: ID, $neighbor: NeighborEnum) {
		survey: surveyNew(uid: $uid) {
			uid: id
			response(id: $id, neighbor: $neighbor) {
				cursor
				hasPrevious
				hasNext
				response {
					...Response
				}
			}
		}
	}
	${RESPONSE_FRAGMENT}
`;
