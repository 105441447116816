import gql from 'graphql-tag';

export default gql`
	query SurveyConnection($first: Int!) {
		surveyConnection(first: $first, after: "opaqueCursor") {
			totalCount
			edges {
				node {
					title
					uid: id
					active
					note
					responseCount
				}
			}
			pageInfo {
				hasNextPage
				endCursor
			}
		}
	}
`;
