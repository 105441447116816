import gql from 'graphql-tag';

export default gql`
	query question($uid: String!, $questionId: ID!) {
		survey: surveyNew(uid: $uid) {
			uid: id
			question(id: $questionId) {
				answered
			}
		}
	}
`;
