import gql from 'graphql-tag';

export default gql`
	fragment UrlParameters on Survey {
		urlParameters {
			list {
				id
				name
			}
		}
	}
`;
