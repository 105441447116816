import gql from 'graphql-tag';

export default gql`
	query user($userId: Int, $page: Int, $perPage: Int!, $orderByName: SortDirection) {
		user(id: $userId) {
			surveyOffsetConnection(page: $page, perPage: $perPage, orderByName: $orderByName) {
				totalCount
				edges {
					node {
						id
						uid: id
						title
						workspace {
							id
							name
						}
						responseCount
						privateIdentifier
					}
				}
			}
		}
	}
`;
