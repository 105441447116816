import gql from 'graphql-tag';

export default gql`
	mutation addSurveyDashboardQuestions($input: AddSurveyDashboardQuestionsInput!) {
		addSurveyDashboardQuestions(input: $input) {
			... on QuestionTile {
				id
				question {
					id
					text
					... on ChoiceQuestion {
						isMultipleAnswer
						hasPictureChoices
						choices {
							id
							open
							ordinal
							quizPoints
							text
							picture {
								url
								w
								h
							}
						}
					}
					... on SemanticDifferentialQuestion {
						pointCount
						rows {
							id
							leftLabel
							rightLabel
							ordinal
						}
					}
					... on MatrixQuestion {
						type
						rows {
							text
							id
						}
						cols {
							text
							id
						}
					}
					... on ImportanceQuestion {
						rows {
							id
							text
						}
					}
					... on TextQuestion {
						subtype: type
					}
				}
				result {
					chartSettings {
						theme {
							id
							name
							isSystem
							isLocked
							colors
						}
						labels
						type
						order
						percentage
					}
					... on ChoicedQuestionAggregate {
						respondentsTotal
						chartSettings
						breakdown {
							answer {
								text
								id
								ordinal
							}
							count
						}
					}
					... on StarRatingQuestionAggregate {
						respondentsTotal
						average
						breakdown {
							answer {
								text
								id
								ordinal
							}
							count
						}
					}
					... on NetPromoterQuestionAggregate {
						summary {
							detractors
							passive
							promoters
							score
						}
						breakdown {
							answer {
								text
								id
								ordinal
							}
							count
						}
						respondentsTotal
					}
					... on MatrixQuestionAggregate {
						respondentsTotal
						rowResults {
							row {
								text
								id
								ordinal
							}
							breakdown {
								answer {
									text
									id
									ordinal
									value
								}
								count
							}
						}
					}
					... on DividePointsQuestionAggregate {
						respondentsTotal
						units
						results {
							item {
								... on TextAnswer {
									text
									id
									ordinal
								}
							}
							average
							sum
						}
					}
					... on SemanticDifferentialQuestionAggregate {
						respondentsTotal
						rowResults {
							labels {
								rightLabel
								leftLabel
							}
							breakdown {
								value
								count
							}
							average
						}
					}
					... on ImportanceQuestionAggregate {
						respondentsTotal
						results: breakdown {
							item {
								... on PresetTextAnswer {
									text
									importanceOrdinal: ordinal
									importanceId: id
								}
							}
							averagePoints
						}
					}
					... on TextQuestionAggregate {
						respondentsTotal
						results {
							value
							count
						}
					}
				}
			}
		}
	}
`;
