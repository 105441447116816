import gql from 'graphql-tag';

export default gql`
	fragment SurveyTemplateCategory on SurveyTemplateCategory {
		title
		id
		sysid
		icon
		templates {
			uid: id
			title
			designed
			templateTypeId
			numberOfCopies
			numberOfQuestions
			theme {
				color
				background
				backgroundImage
			}
		}
	}
`;
