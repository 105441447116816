import gql from 'graphql-tag';

export default gql`
	fragment footer on Survey {
		poweredBySurvioButton {
			free
			shown
		}
		footerText
		allowReturn
	}
`;
