import gql from 'graphql-tag';

export default gql`
	mutation activateSurvey($uid: String!) {
		activateSurvey(input: { uid: $uid }) {
			uid: id
			active
		}
	}
`;
