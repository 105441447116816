import mutation from '~/cache/mutation';

export default mutation('setSettings', {
	propName: 'setChartSettings',
	optimisticResponse: {
		setQuestionAnalyticsPreferences: {
			success: true,
			message: null,
			__typename: 'MutationResult',
		},
	},
	update: ({
		cache,
		getQuery,
		variables: {
			uid,
			question,
			value: { color, ...value },
		},
	}) => {
		const query = getQuery('survey/withId', ['QuestionResults']);
		const variables = { uid, id: question };
		const data = cache.readQuery({ query, variables });

		const newData = {
			...data,
			survey: {
				...data.survey,
				question: {
					...data.survey.question,
					results: {
						...data.survey.question.results,
						chartSettings: {
							...value,
							theme: {
								...data.survey.question.results.chartSettings.theme,
								colors: color?.list || data.survey.question.results.chartSettings.theme.colors,
							},
						},
					},
				},
			},
		};

		cache.writeQuery({
			query,
			variables,
			data: newData,
		});
	},
});
