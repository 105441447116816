import gql from 'graphql-tag';

export default gql`
	query surveyOffsetConnection($perPage: Int!, $page: Int) {
		surveyOffsetConnection(perPage: $perPage, page: $page) {
			edges {
				node {
					title
					uid: id
					responseCount
					workspace {
						id
						name
					}
				}
			}
			totalCount
		}
	}
`;
