import gql from 'graphql-tag';

export default gql`
	fragment DashboardList on Survey {
		uid: id
		dashboards {
			id
			title
			createdAt
			sharing {
				enabled
			}
		}
	}
`;
