import gql from 'graphql-tag';

export default gql`
	fragment AutomatedReports on Survey {
		uid: id
		dashboard(dashboardId: $id) {
			id
			automatedReports {
				enabled
				to
				when
				repeat
			}
		}
	}
`;
