import pathOr from 'ramda/es/pathOr';
import { graphql } from '@apollo/client/react/hoc';
import GetQuery from './get';
const Query = (queryType = '', queryOptions = {}) => {
	let { propName, mapProps, options, loading, fragments, optimisticResponse, result, skip } =
		queryOptions;
	const query = GetQuery(queryType, fragments);
	if (typeof propName === 'string') propName = [propName];
	if (typeof result === 'object') result = [result];

	const config = {
		props: ({ data, ownProps }) => {
			if (data.loading) {
				return {
					[loading || 'loading']: true,
				};
			}

			const props = {};
			props.refetch = data.refetch;
			props.fetchMore = data.fetchMore;
			props.networkStatus = data.networkStatus;

			// get operations name or alias
			query.definitions
				.filter((a) => a.kind === 'OperationDefinition')
				.forEach((def, i) => {
					const selection = def.selectionSet.selections[0];
					const fieldName = selection.alias ? selection.alias.value : selection.name.value;

					props[(propName && propName[i]) || fieldName] = result
						? pathOr(result[i].or, result[i].path, data[fieldName])
						: data[fieldName];
				});

			if (typeof mapProps === 'function') {
				return mapProps(props, ownProps);
			}

			return props;
		},
	};

	if (options) config.options = options;
	if (optimisticResponse) config.optimisticResponse = optimisticResponse;
	if (skip) config.skip = skip;

	return graphql(query, config);
};

export default Query;
