import gql from 'graphql-tag';

const query = (children, fragments) => gql`
		query survey($uid: String!, $id: ID!) {
			survey: surveyNew(uid: $uid) {
			uid: id
			${children}
			}
		}
		${fragments}
	`;

export default query;
