import gql from 'graphql-tag';

export default gql`
	mutation keepAutoRenewal {
		keepAutoRenewal {
			success
			message
			eligibleForRenewalDiscount
		}
	}
`;
